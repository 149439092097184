<script>
import { ref } from "vue";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import addVideo from "../../../components/widgets/addVideo.vue";
import { Api } from "../../../helpers";
import { Erreur } from "../../../helpers/error";
import VideosCard from "../../../components/widgets/VideosCard.vue";
import Swal from "sweetalert2";
import sectionVideoCard from "../section/component/sectionVideoCard.vue";
import addButton from "../../../components/widgets/addButton.vue";
import loaderProgress from "../../../components/widgets/loaderProcess.vue";
// import addButton from "../../../components/widgets/addButton.vue";
/**
 * Projects-create component
 */
export default {
  page: {
    title: "Video list",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    // addButton,
    addVideo,
    VideosCard,
    sectionVideoCard,
    addButton,
    loaderProgress,
  },
  data() {
    return {
      process: false,
      spinner: false,
      checkedf: false,
      progress: 0,
      titre: "",
      searchQuery: "",
      addMovieModal: false,
      addSectionMovieModal: false,
      SectionMovieModalSelectList: false,
      programme: "",
      image: "",
      sectionsProgramme: [],
      videosInProgram: [],
      videoGroupByMonth: [],
      categorieList: [],
      tagList: [],
      programList: [],
      sectionsVideoList: [],
      sectionVideos: {
        title: "",
        description: "",
      },
      idVideos: [],
      title: "Program title",
      items: [
        {
          text: "Programmes",
          active: true,
        },
      ],
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: { "My-Awesome-Header": "header value" },
      },
      daterange: ref(new Date()),
      idProgram: "",
    };
  },
  methods: {
    showAddMovieModal() {
      this.addMovieModal = !this.addMovieModal;
    },

    showAddSectionMovieModal() {
      this.addSectionMovieModal = true;
      this.SectionMovieModalSelectList = false;
    },

    showSectionMovieModalSelectList() {
      this.addSectionMovieModal = false;
      this.SectionMovieModalSelectList = true;
    },

    createSectionVideo() {
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        console.log(this.progress);
      };
      console.log(this.programme);
      this.SectionMovieModalSelectList = false;
      this.process = true;
      this.titre = "Ajout d'une section";
      Api.post(
        `/streamvodv2/rest/v2/video-section/create?program_id=${this.programme.id}`,
        {
          videoIds: this.idVideos,
          videoSection: {
            description: this.sectionVideos.description,
            isInProgram: true,
            isOnHomePage: false,
            title: this.sectionVideos.title,
          },
        },
        { onUploadProgress }
      )
        .then(() => {
          this.process = false;
          Swal.fire("Succes!", "Section Créée", "success");
          // location.reload()
        })
        .catch((error) => {
          this.process = false;
          Erreur.gestionErreur(error.message);
        });
    },
    groupVideosByMonth(videos) {
      // Créer un objet pour stocker les vidéos groupées par mois
      const groupedVideos = {};

      videos.forEach((video) => {
        // Extraire la date de création de la vidéo
        const creationDate = new Date(video.releaseDate);

        // Récupérer le mois et l'année en format "Mois Année"
        let month = creationDate.toLocaleString("fr-FR", {
          month: "long",
          year: "numeric",
        });

        // Mettre la première lettre du mois en majuscule
        month = month.charAt(0).toUpperCase() + month.slice(1);

        // Initialiser la clé du mois si elle n'existe pas
        if (!groupedVideos[month]) {
          groupedVideos[month] = [];
        }

        // Ajouter la vidéo au mois correspondant
        groupedVideos[month].push(video);
      });

      // Convertir l'objet en un tableau d'objets avec le format désiré
      return Object.keys(groupedVideos).map((month) => ({
        mois: month,
        videos: groupedVideos[month],
      }));
    },

    formatDuration(duration) {
      const hours = Math.floor(duration / 3600);
      const minutes = Math.floor((duration % 3600) / 60);
      const seconds = Math.floor(duration % 60);

      const hoursString = hours.toString().padStart(2, "0");
      const minutesString = minutes.toString().padStart(2, "0");
      const secondsString = seconds.toString().padStart(2, "0");

      return `${hoursString}:${minutesString}:${secondsString}`;
    },
    formatDateByMonth(date) {
      const d = new Date(date);

      const day = d.getDate();
      const month = d.toLocaleString("fr-FR", { month: "long" });
      const year = d.getFullYear();

      return `${day} ${month.charAt(0).toUpperCase() + month.slice(1)} ${year}`;
    },
  },

  mounted() {
    this.spinner = true;

    Api.get("/streamvodv2/api/v2/program/all")
      .then((res) => {
        this.programList = res.data.content;
      })
      .catch((error) => {
        Erreur.gestionErreur(error.message);
      });

    Api.get("/streamvodv2/api/v2/program/get/" + this.$route.params.uiid)
      .then((res) => {
        this.programme = res.data.content;
        console.log(this.programme);
        this.idProgram = this.programme.id;
        console.log(this.idProgram);
        this.image = this.programme.imageLandscape;
        this.sectionsVideoList = this.programme.videoSections;
        this.$refs.videoPlayer.src = this.programme.videoCover;
      })
      .catch((error) => {
        this.spinner = false;
        Erreur.gestionErreur(error.message);
      });

    Api.get("/streamvodv2/api/v2/program/all-videos/" + this.$route.params.uiid)
      .then((res) => {
        this.spinner = false;
        this.videosInProgram = res.data.content;
        this.videosInProgram = this.videosInProgram.sort((a, b) => {
          return new Date(b.releaseDate) - new Date(a.releaseDate);
        });
        this.videoGroupByMonth = this.groupVideosByMonth(this.videosInProgram);
        console.log(this.videoGroupByMonth);
      })
      .catch((error) => {
        this.spinner = false;
        Erreur.gestionErreur(error.message);
      });

    Api.get("/streamvodv2/api/v2/category/all")
      .then((res) => {
        this.categorieList = res.data.content;
      })
      .catch((error) => {
        Erreur.gestionErreur(error.message);
      });

    Api.get("/streamvodv2/api/v2/tag/all")
      .then((res) => {
        this.tagList = res.data.content;
      })
      .catch((error) => {
        Erreur.gestionErreur(error.message);
      });
  },

  computed: {
    filteredVideoList() {
      if (!this.searchQuery) {
        return this.videosInProgram;
      }
      return this.videosInProgram.filter((video) => {
        const fullName = video.title;
        return fullName.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <loaderProgress :visible="process" :progress="progress" :title="titre" />
    <div
      style="display: flex; justify-content: space-between; margin-bottom: 2rem"
    >
      <div></div>
      <div style="display: flex" v-if="idProgram">
        <addVideo :id-program="idProgram"></addVideo>
        <addButton
          @click="showAddSectionMovieModal()"
          :text="'Créer une section'"
        ></addButton>
      </div>

      <!-- <addButton @click="showAddMovieModal()"></addButton> -->
    </div>

    <!-- <b-modal
      v-model="addMovieModal"
      id="modal-lg"
      size="s"
      title="Que souhaitez-vous faire?"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <div class="row">
          <button
            type="submit"
            style="border-radius: 8px; width: 100%"
            class="btn c2play-primary"
            @click="showAddSectionMovieModal"
          >
            Créer une nouvelle section
          </button>
        </div>
        <div class="row mt-2">
          <button
            type="submit"
            style="border-radius: 8px; width: 100%"
            class="btn c2play-primary"
            @click="showImportModalRef"
          >
            Modifier une section existante
          </button>
        </div>
      </div>
    </b-modal> -->

    <div class="card" style="padding: 30px; border-radius: 7px">
      <div class="row">
        <div class="col-xl-7">
          <div class="product-detai-imgs" style="">
            <div
              style="
                position: relative;
                padding-bottom: 56.25%;
                height: 0;
                overflow: hidden;
              "
              itemscope
              itemtype="https://schema.org/VideoObject"
            >
              <video
                ref="videoPlayer"
                src=""
                controls
                style="
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  left: 0px;
                  top: 0px;
                  overflow: hidden;
                "
                width="720"
                height="480"
              ></video>
            </div>
          </div>
        </div>
        <div class="col-xl-5">
          <div class="mt-3">
            <div style="text-align: justify">
              <h2>{{ programme.title }}</h2>
              <br />
              <h5>
                <strong>Description</strong>
                : {{ programme.description }}
              </h5>
              <h5><strong>Durée</strong> : {{ programme.duration }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal pour la création d'une section de vidéo -->
    <b-modal
      v-model="addSectionMovieModal"
      id="modal-xl"
      size="xl"
      title="Créer un section de vidéo"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <form>
          <div class="row">
            <div class="col-lg-12">
              <label class="col-form-label">Titre</label>
              <div class="col-md-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="text"
                  class="form-control"
                  v-model="sectionVideos.title"
                  placeholder="Entrez le titre"
                />
              </div>
            </div>
          </div>
          <div class="form-group row mb-4">
            <label for="projectbudget" class="col-form-label"
              >Description</label
            >

            <div class="col-lg-12">
              <textarea
                id="projectdesc"
                class="form-control"
                rows="3"
                v-model="sectionVideos.description"
                placeholder="Entrez la description"
              ></textarea>
            </div>
          </div>
        </form>
        <div style="display: flex; align-items: center; justify-content: end">
          <button
            @click="showSectionMovieModalSelectList()"
            class="btn c2play-primary"
          >
            suivant
          </button>
        </div>
      </div>
    </b-modal>

    <b-modal
      v-model="SectionMovieModalSelectList"
      id="modal-xl"
      size="xl"
      title="Créer un section de vidéo"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div>
            <button
              @click="showAddSectionMovieModal()"
              class="btn c2play-primary"
            >
              Retour
            </button>
          </div>
          <div>
            <button @click="createSectionVideo()" class="btn c2play-primary">
              Ajouter
            </button>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-lg-6">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="Rechercher une vidéo"
                style="border-radius: 8px"
                v-model="searchQuery"
              />
            </div>
          </div>
          <div class="col-lg-6" style="text-align: end">
            {{ idVideos.length }} vidéo<span v-if="idVideos.length > 1">s</span>
            séléctionée<span v-if="idVideos.length > 1">s</span>
          </div>
        </div>

        <br />

        <div class="table-responsive" style="height: 80vh; overflow: scroll">
          <table class="table align-middle table-nowrap table-hover">
            <thead class="table-light">
              <tr>
                <th scope="col" style="width: 20px">Select</th>
                <th scope="col" style="width: 100px">Image</th>
                <th scope="col" style="width: 400px">Titre</th>
                <th scope="col">Date de publication</th>
                <th scope="col">Durée</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="video in filteredVideoList" :key="video.id">
                <td>
                  <b-form-checkbox
                    v-model="idVideos"
                    :value="video.id"
                    name="checkbox-1"
                  ></b-form-checkbox>
                </td>
                <td>
                  <img
                    :src="video.thumbnail"
                    alt="image"
                    height="60"
                    width="100"
                    style="border-radius: 7px"
                  />
                </td>
                <td>{{ video.title }}</td>
                <td>{{ formatDateByMonth(video.releaseDate) }}</td>
                <td>{{ formatDuration(video.duration) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-modal>
    <!-- =============================================================================================== -->

    <div style="text-align: center; font-size: 3em" v-if="spinner == true">
      <b-spinner variant="success" role="status"></b-spinner>
    </div>
    <div
      style="text-align: center; font-size: 3em"
      v-if="spinner == false && videosInProgram.length == 0"
    >
      <span style="font-size: 1.5rem"
        >Aucune vidéo créée dans ce programme !</span
      >
    </div>

    <!-- <div
      v-if="
        categorieList.length > 0 && tagList.length > 0 && programList.length > 0
      "
    > -->
    <div role="tablist">
      <b-card no-body class="mb-1 shadow-none">
        <b-card-header header-tag="header" role="tab">
          <h6 class="m-0">
            <a
              v-b-toggle.accordion-1
              class="text-dark"
              href="javascript: void(0);"
              ><h1 v-if="sectionsVideoList.length > 0">
                Liste des sections
              </h1></a
            >
          </h6>
        </b-card-header>
        <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>
              <sectionVideoCard
                v-for="section in sectionsVideoList"
                :key="section.id"
                :section="section"
                :video-list="videosInProgram"
                :program-list="programList"
                :tag-list="tagList"
                :categorie-list="categorieList"
              ></sectionVideoCard>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" role="tab">
          <h6 class="m-0">
            <a
              v-b-toggle.accordion-2
              class="text-dark"
              href="javascript: void(0);"
              ><h1 v-if="videoGroupByMonth.length > 0">Liste des Vidéos</h1></a
            >
          </h6>
        </b-card-header>
        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>
              <div
                class="row"
                v-for="month in videoGroupByMonth"
                :key="month.index"
              >
                <h2 class="mt-2">{{ month.mois }}</h2>
                <VideosCard
                  v-for="video in month.videos"
                  :key="video.id"
                  :video-props="video"
                  :categ="categorieList"
                  :tag="tagList"
                  :programme="programList"
                  :showOption="true"
                ></VideosCard>
              </div>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
    <!-- </div> -->

    <!-- end row -->
  </Layout>
</template>
